<template>
  <div class="body">
    <Header titleurl="recruit"></Header>
    <div>
      <div class="top">
        <div class="container">
          <div class="head">
            <div class="title fl">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/recruit' }"
                  >牛豆豆招聘</el-breadcrumb-item
                >
                <el-breadcrumb-item>招聘详情</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="positionleft highfirst">
          <div class="tofirst">
            <div class="tofirst_left fl">
              <span class="tofirst_left spanFrist"
                >更新：{{ getjobDetailList.updateNum }}天前</span
              >
              <span class="tofirst_left spanSecond"
                >浏览：{{ getjobDetailList.browsenum }}人</span
              >
              <span class="tofirst_left spanThree"
                >申请：{{ getjobDetailList.applyNumber }}人</span
              >
            </div>
            <div class="tofirst_right fr">
              <span class="fl">
                <!-- 没有收藏，点击收藏 -->
                <div v-if="collectionId == 0" @click="collection(0)">
                  <img src="../../assets/img/xingoff.png" />
                  收藏
                </div>
                <!-- 收藏，点击取消收藏 -->
                <div v-else @click="collection(collectionId)">
                  <img src="../../assets/img/xingon.png" />
                  收藏
                </div>
              </span>
              <span class="fl">
                <el-popover
                  placement="bottom"
                  title="分享到"
                  width="200"
                  trigger="hover"
                >
                  <share :config="config"></share>
                  <span slot="reference">
                    <img src="../../assets/img/share.png" />分享
                  </span>
                </el-popover>
              </span>
              <span class="fl">
                <img src="../../assets/img/report.png" />举报
              </span>
            </div>
          </div>
          <div class="tosecond">
            <span class="fl">{{ getjobDetailList.job }}</span>
            <span class="fr money">{{
              getjobDetailList.salary === '面议' ||
              getjobDetailList.salary === ''
                ? '面议'
                : getjobDetailList.salary
                ? getjobDetailList.salary + '/月'
                : ''
            }}</span>
          </div>
          <div class="tothird">
            <span class="fl">{{ getjobDetailList.job }}</span>
          </div>
          <div class="tofourth">
            <span v-for="(item, index) in welfare" :key="index">{{
              item
            }}</span>
          </div>
          <div class="tofifth">
            <span>{{
              getjobDetailList.num === null
                ? '招人'
                : getjobDetailList.num
                ? '招' + getjobDetailList.num
                : ''
            }}</span>
            <span class="bar" v-if="!getjobDetailList ? false : true">|</span>
            <span>{{ getjobDetailList.education }}</span>
            <span class="bar" v-if="!getjobDetailList ? false : true">|</span>
            <span>{{ getjobDetailList.workExp }}</span>
          </div>
          <div class="tosixth">
            <img src="../../assets/img/location.png" width="25" height="25" />
            <span>{{ address }}</span>
            <span class="lookmap" @click="dialogVisible = true">查看地图</span>
          </div>
          <div class="toseventh">
            <el-button type="danger" class="danger" @click="getJobRequest"
              >申请岗位</el-button
            >
            <!-- <img src="../../assets/img/weliao.png" /> -->
            <img src="../../assets/img/phonetalk.png" @click="getPhone" />
          </div>
        </div>
        <div class="positionright highfirst">
          <div class="right_name">{{ getjobDetailList.companyName }}</div>
          <div class="right_class">{{ getjobDetailList.trade }}</div>
          <div class="right_class">{{ getjobDetailList.companySize }}</div>
          <div class="recruit">企业招聘（已认证企业）</div>
          <div class="authentication">
            <img src="../../assets/img/ordinary.png" />
            <span>普通企业认证</span>
            <img src="../../assets/img/senior.png" />
            <span>高级实名认证</span>
          </div>
          <div class="record">
            <div class="record_child">
              <span>
                {{ getjobDetailList.viewingRate }}
                <span>%</span>
              </span>
              <div>简历查看率</div>
            </div>
            <div class="record_child">
              <span
                class="recruitPosition"
                @click="recruitposition(companyIds)"
              >
                {{ getjobDetailList.recruitNum }}
                <span>个</span>
              </span>
              <div>招聘职位</div>
            </div>
            <div class="record_child">
              <span>
                {{ getjobDetailList.monthNum }}
                <span>月</span>
              </span>
              <div>加入牛豆豆</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container consecond">
        <div class="positionleft highsecond">
          <div class="title_name">职位描述</div>
          <div>任职要求：</div>
          <div class="requirement" v-html="getjobDetailList.requires"></div>
          <div>岗位职责：</div>
          <div class="duty" v-html="getjobDetailList.duty"></div>
          <div class="title_name">公司介绍</div>
          <!-- <img src="../../assets/img/gaosheng.png" /> -->
          <div v-html="getjobDetailList.cmpInfo"></div>
        </div>
        <div class="positionright highsecond">
          <div class="title_name">职位推荐</div>
          <div class="work" v-for="(item, index) in recommend" :key="index">
            <div class="workleft">
              <div class="worker">{{ item.positionName }}</div>
              <div class="road">{{ item.county }}</div>
              <div class="face">
                {{
                  item.salary === '面议'
                    ? '面议'
                    : item.salary === ''
                    ? ''
                    : item.salary + '/月'
                }}
              </div>
              <div class="welfare">
                <span v-for="(it, i) in item.welfare.split(',')" :key="i">{{
                  it
                }}</span>
              </div>
            </div>
            <el-button type="default" @click="apply(item.recruitId)"
              >申请</el-button
            >
          </div>
        </div>
      </div>
      <div class="conthird"></div>
      <el-dialog
        :visible.sync="dialogVisible"
        top="25vh"
        width="40%"
        :before-close="handleClose"
      >
        <el-row>
          <div class="amap-page-container" style="margin-top: -15px">
            <div class="address"></div>
            <el-amap
              vid="amapDemo"
              :center="center"
              :zoom="zoom"
              class="amap-demo"
              :events="events"
            >
              <el-amap-marker
                v-for="(marker, index) in markers"
                :position="marker"
                :key="index"
              ></el-amap-marker>
            </el-amap>
          </div>
        </el-row>
      </el-dialog>
      <!-- 电话的弹出框 -->
      <el-dialog
        :visible.sync="dialogVisiblePhone"
        width="300px"
        class="Dialog"
      >
        <div style="margintop: -20px">请与我联系</div>
        <span>{{ getjobDetailList.contactsPhone }}</span>
      </el-dialog>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import {
  jobDetail,
  jobRequest,
  positionrecommend,
  addcollection,
  delcollection,
} from '../../api/position';
import { candidateId } from '../../api/jobwant';
import Utils from '../../utils/unil';
export default {
  name: 'positiondetail',
  components: {
    Header,
    Footer,
  },
  data() {
    const self = this;
    return {
      id: null,
      dialogVisible: false,
      gisCoord: {},
      address: '郑州市中原区林山寨街道天龙大厦',
      center: [113.624097, 34.755082],
      markers: [],
      zoom: 15,
      recruitId: 1,
      paramPosition: {
        recruitId: 1,
        keyword: '',
        pageIndex: 1,
        pageSize: 4,
      },
      config: {
        url: window.location.href,
        sites: ['qq', 'wechat', 'weibo'],
        wechatQrcodeTitle: '微信扫一扫：分享',
        wechatQrcodeHelper:
          '<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>',
      },
      //招聘公司信息主键
      reeCid: null,
      //职位推荐
      recommend: [],
      // 职位列表数据
      getjobDetailList: {
        salary: '',
      },
      //收藏
      collectionId: 0,
      bussId: 0,
      // 电话弹出框的显示与隐藏
      dialogVisiblePhone: false,
      vo: {
        // 简历id
        candidateId: 1,
        // 投递公司id
        companyId: 0,
        // 招聘uid
        recruitId: 0,
        // 招聘主键id
        ids: 0,
        // 公司id
        companyIds: 0,
      },
      welfare: [],
      events: {
        click(e) {
          self.markers = [];
          let { lng, lat } = e.lnglat;
          self.center = [lng, lat];
          self.markers.push([lng, lat]);
        },
      },
      desCompanyId:''
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.desCompanyId = this.$route.query.desCompanyId;
    this.getjobDetail(this.id,this.desCompanyId);
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    collection(val) {
      if (val === 0) {
        addcollection(this.bussId).then((res) => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '收藏成功',
            });
            this.getjobDetail(this.bussId,this.desCompanyId);
          } else {
            this.$message.error('收藏失败');
          }
        });
      } else {
        delcollection(this.bussId).then((res) => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '取消收藏成功',
            });
            this.getjobDetail(this.bussId,this.desCompanyId);
          } else {
            this.$message.error('取消收藏失败');
          }
        });
      }
    },
    // 发布职位信息
    async getjobDetail(id,desCompanyId) {
      const { data: res } = await jobDetail(id,desCompanyId);
      if (res.code == 1) {
         if(res.result.isEffective!=1){
            this.$message({
            showClose: true,
            message: '信息正在审核中',
            duration:0,
            type: 'warning'
          });
        }
        this.getjobDetailList = res.result;
        this.gisCoord = res.result.gisCoord;
        this.center = [
          res.result.gisCoord.longitude,
          res.result.gisCoord.latitude,
        ];
        this.markers.push([
          res.result.gisCoord.longitude,
          res.result.gisCoord.latitude,
        ]);
        this.address =
          res.result.gisCoord.province +
          res.result.gisCoord.city +
          res.result.gisCoord.county;
        this.vo.recruitId = res.result.id;
        this.vo.companyId = res.result.cid;
        this.companyIds = res.result.cid;
        let welfare = res.result.welfare;
        this.welfare = res.result.welfare.split(',');
        this.collectionId = res.result.collectionId;
        this.bussId = res.result.id;
        this.reeCid = res.result.reeCid;
        this.paramPosition.recruitId = res.result.id;
        this.getpositionrecommend();
      } else {
        return this.$message.error('获取职位明细失败');
      }
    },
    //推荐职位
    getpositionrecommend() {
      positionrecommend(this.paramPosition).then((res) => {
        this.recommend = res.data.result.list;
      });
    },
    // 电话弹出框
    getPhone() {
      let phone = this.getjobDetailList.contactsPhone;
      // let hideReason = this.getjobDetailList.hideReason
      if (!phone) {
        Utils.$emit('demo', 'msg');
      } else {
        // if(hideReason == null){
        //   this.dialogVisiblePhone = false;
        //   this.$message.error('你还不是求职者！')
        // }else{
        //    this.dialogVisiblePhone = true;
        // }
        this.dialogVisiblePhone = true;
      }
    },
    // 申请岗位
    getJobRequest() {
      //获取简历id
      candidateId().then((res) => {
        this.vo.candidateId = res.data.result;
        if (this.vo.candidateId === 0) {
          this.$message.error('对不起，您没有简历，不能申请该岗位');
        } else {
          jobRequest(this.vo).then((res) => {
            if (res.data.code === 1) {
              this.$message({
                type: 'success',
                message: '申请岗位成功',
              });
            }
          });
        }
      });
    },
    //获取简历id
    getCandidateId() {
      candidateId().then((res) => {
        this.vo.candidateId = res.data.result;
      });
    },
    //申请
    apply(recruitId) {
      this.$router.push({
        path: '/recruit/positiondetail',
        query: { id: recruitId },
      });
      this.getjobDetail(recruitId,this.desCompanyId);
      window.scrollTo(0, 0);
    },
    //招聘岗位
    recruitposition(id) {
      const self = this;
      if (this.reeCid !== null) {
        this.$router.push({
          path: '/recruit/recruitposition',
          query: { reeCid: id },
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.amap-demo {
  height: 500px;
}
>>> .el-dialog__wrapper .el-dialog .el-dialog__header {
  padding: 0px 20px 20px 0;
  margin-bottom: 10px;
}
>>> .el-dialog__wrapper .el-dialog .el-dialog__body {
  padding: 10px 20px 30px 20px;
}
/* .el-dialog__wrapper .el-dialog .el-dialog__header .el-dialog__title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  margin: 20px;
} */
.address {
  margin-top: 10px;
}
.contain {
  width: 100%;
  height: 500px;
}
.body {
  background-color: #f3f3f3;
  width: 100%;
}
.top {
  width: 100%;
  /* border-top: 1px solid #fff; */
  /* background-color: #fff; */
  margin-bottom: 20px;
}
.fr {
  float: right;
}
.spanFrist {
  width: 100px;
  position: absolute;
  top: 0;
}
.spanSecond {
  width: 100px;
  position: absolute;
  top: 0;
  left: 109px;
}
.spanThree {
  width: 100px;
  position: absolute;
  top: 0;
  left: 210px;
}
.head {
  height: 60px;
}
.head .title {
  color: #999999;
  margin: 30px 0 0 0px;
}
.head .title .el-breadcrumb {
  font-size: 12px;
}
.head .button {
  margin: -20px 0 0 100px;
}
.head .button .el-button {
  height: 30px;
  padding: 6px 20px;
  outline: none;
  color: #2e93ff;
  background-color: #ffefe7;
}
.container {
  width: 1140px;
  margin: 0 auto;
}
.positionleft {
  padding: 30px 20px;
  margin-bottom: 10px;
  width: 700px;
  float: left;
  background-color: #fff;
}
.highfirst {
  height: 355px;
}
.positionright {
  padding: 30px 20px;
  width: 330px;
  margin-bottom: 10px;
  float: right;
  background-color: #fff;
}
.container .positionleft .tofirst,
.container .positionright .right_class {
  color: #9ba1aa;
  font-size: 12px;
  margin: 5px 0;
}
.fl {
  /* float: left; */
  position: relative;
}
.fr {
  float: right;
}
.container .positionleft .tofirst .tofirst_right span {
  cursor: pointer;
  margin-right: 40px;
}
.container .positionleft .tofirst .tofirst_right span img {
  float: left;
  margin-right: 5px;
}
/* .container .positionleft .tofirst span {
  margin-right: 40px;
} */
.container .positionleft .tofirst span:last-child {
  margin-right: 0px;
}
.container .positionleft .tosecond {
  clear: both;
  margin-top: 50px;
  font-size: 32px;
  font-weight: 700;
}
.container .positionleft .tosecond .money {
  color: #ff552e;
  margin-right: 20px;
}
/* .container .positionleft .tosecond .yuan{
  font-size: 18px;
  font-weight: 500;
  margin-left: 8px;
} */
.container .positionleft .tothird {
  padding-top: 10px;
  clear: both;
  font-size: 17px;
}
.container .positionleft .tofourth {
  margin-top: 40px;
  clear: both;
  color: #497e9d;
  font-size: 12px;
}
.container .positionleft .tofourth span {
  margin: 0 20px 10px 0;
  display: inline-block;
}
.container .positionleft .tofifth {
  margin-top: 30px;
}
.container .positionleft .tofifth span {
  margin-right: 10px;
}

.bar {
  color: #e9e7e7;
}
.lookmap {
  color: #72c7f6;
  cursor: pointer;
  margin-left: 10px;
}
.container .positionleft .tosixth {
  margin: 10px 0 20px 0;
}
.container .positionleft .tosixth img {
  vertical-align: middle;
}
.danger {
  background-color: #ff552e;
  color: #fff;
  font-size: 18px;
  border: 0;
  border-radius: 0px;
  padding: 15px 80px;
}
.toseventh img {
  margin-left: 30px;
  margin-bottom: -10px;
  cursor: pointer;
}
.container .positionright .right_name {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  max-width: 290px;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container .positionright .recruit {
  margin: 30px 0 20px 0;
  color: #ea8225;
}
.container .positionright .authentication span {
  margin: 0 8px;
  float: left;
}
.container .positionright .authentication img {
  float: left;
}
.container .positionright .record {
  clear: both;
}
.container .positionright .record .record_child {
  margin: 40px 0 0 20px;
  float: left;
  text-align: center;
}
.container .positionright .record .record_child .recruitPosition {
  cursor: pointer;
}
.container .positionright .record .record_child span {
  font-weight: 600;
  font-size: 13px;
}
.container .positionright .record .record_child > span {
  font-size: 20px !important;
}
.container .positionright .record .record_child div {
  font-size: 12px;
  color: #9ba1aa;
}
.container .title_name {
  font-size: 18px;
  font-weight: 600;
}
.consecond {
  min-height: 300px;
  overflow-y: hidden;
}
.conthird {
  margin-top: 30px;
}
.container .highsecond {
  margin-bottom: -20000px;
  padding-bottom: 20000px;
}
.container .highsecond div {
  line-height: 30px;
}
.container .highsecond img {
  margin: 20px 0;
  width: 150px;
  height: 150px;
}
.container .highsecond .requirement {
  line-height: 30px;
}
.container .highsecond .work {
  margin-top: 20px;
  clear: both;
  min-height: 120px;
}
.container .highsecond .workleft {
  margin: 0px 20px 20px 0px;
  float: left;
}
.container .highsecond .el-button {
  border: 1px solid #ff552e;
  color: #ff552e;
  border-radius: 0;
  margin: 20px 50px 0 0;
  float: right;
}
.container .highsecond .worker {
  font-size: 15px;
  max-width: 150px;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container .highsecond .road {
  color: #999999;
}
.container .highsecond .face {
  color: #ff552e;
}
.container .highsecond .welfare span {
  padding: 20px 10px 30px 0;
  color: #497e9d;
  font-size: 12px;
}
.Dialog {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  margin: 20px;
}
</style>
