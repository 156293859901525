import http from '@/utils/http'

// 求职者列表
export const datagridcandidate = (params) =>{
    return http.post('/reeresume/getcandidatelist', params)
}
//求职过滤条件
export const candidatefilter = () =>{
    return http.get('/reetalentsinvite/gettalentsoptions')
}

// 根据用户id，获取简历id
export const candidateId = () =>{
    return http.get('/reeresume/getcandidateid')
}
// 面试邀请列表
export const getmyinterviewlist = (params) =>{
    return http.post('/reetalentsinvite/getmyinterviewlist',params)
}
// 拒绝面试邀请
export const refuseinterview = (params) =>{
    return http.post('/reetalentsinvite/refuseinterview?id='+params)
}

// 简历下载 
export const downloadcandidate = (id) =>{
    return http.get1('/reetalentsinvite/downloadcandidate?candidateId='+id)
}

// 面试邀请详情
export const getinterviewdetai = (id) =>{
    return http.get('/reetalentsinvite/getinterviewdetail?id='+id)
}

