import http from '@/utils/http'
import qs from 'qs'
//招聘筛选条件
export const screen = (params) =>{
    return http.get('/reetalentsinvite/gettalentsoptions?cityCode='+params)
}
//职位发布
export const jobpost = (params) =>{
    return http.post('/reetalentsinvite/jobpost',params)
}

//发布招聘企业信息
export const jobinfo = (params) =>{
    return http.post('/reetalentsinvite/reecompanyinfopost',params)
}

//根据业务id,删除附件信息
export const businessId = (params) =>{
    return http.delete('/store/business/'+params)
}

// 职位明细
export const jobDetail = (id,desCompanyId) =>{
    return http.get('/reetalentsinvite/getrecruitdetail?recruitId='+id+'&desCompanyId='+desCompanyId)
}

// 发起职位申请
export const jobRequest = (params) =>{
    return http.post('/reetalentsinvite/addjobrequest',params)
}
// 推荐职位
export const positionrecommend = (params) =>{
    return http.post('/reetalentsinvite/getrecommendrecruitlist',params)
}
// 添加收藏
export const addcollection = (params) =>{
    return http.post('/reetalentsinvite/addjobcollection?bussId='+params)
}
// 取消收藏
export const delcollection = (params) =>{
    return http.post(`/reetalentsinvite/cancelcollection?bussId=${params}&type=1`)
}
// HR公司列表
export const hrcompany = () =>{
    return http.get('/user/getcurruserworkspace')
}
// HR公司信息
export const hrcompanyinfo = (params) =>{
    return http.get('/reetalentsinvite/getreecompanydetail?cid='+params)
}
// 公司明细
export const cmpdetail = (params) =>{
    return http.post('/reetalentsinvite/getreecompanyjobs',params)
}
//获取职位列表
export const reetalentsinvitegetpositionlist = (params) => {
    return http.postjson('/reetalentsinvite/getpositionlist',params)
}
export const reetalentsinvitegecompanylist = (params) =>{
    return http.postjson('/reetalentsinvite/getreecompanylist',params)
}

// 我的求职申请列表
export const getmydeliverrecord = (params) =>{
    return http.post('/reetalentsinvite/getmydeliverrecord',params)
}
// 取消投递
export const reetalentsinvitecanceldeliver = (params) =>{
    return http.post(`/reetalentsinvite/canceldeliver?${qs.stringify(params)}`)
}





